<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-25 13:00:42
 * @LastEditTime: 2020-05-07 10:50:25
 -->
<template>
	<div v-loading="loading" class="app-container">
		<el-form :model="searchForm" inline ref="form" class="searchForm" size="small">
			<el-form-item label="时间">
				<el-date-picker
					type="year"
					:clearable="false"
					v-model="searchForm.year"
					value-format="yyyy"
					format="yyyy"
					placeholder="选择年份"
					style="width: 160px"
				></el-date-picker>
			</el-form-item>
			<el-button type="primary" size="small" @click="_getList" icon="el-icon-search">查询</el-button>
			<el-button type="primary" size="small" @click="drawer = true" icon="el-icon-setting">设置</el-button>
			<el-button type="primary" size="small" style="float: right" @click="_export" icon="el-icon-download">导出</el-button>
		</el-form>
		<el-table :data="list" stripe>
			<el-table-column label="数据/指标项目" prop="name" align="center"></el-table-column>
			<el-table-column label="单位" prop="unit" align="center"></el-table-column>
			<el-table-column :label="`${searchForm.theYearBeforeLast}年`" sortable prop="theYearBeforeLast" align="center">
				<template slot-scope="scope">
					{{ scope.row.theYearBeforeLast }}
				</template>
			</el-table-column>
			<el-table-column :label="`${searchForm.lastYear}年`" sortable prop="lastYear" align="center">
				<template slot-scope="scope">
					{{ scope.row.lastYear }}
				</template>
			</el-table-column>
			<el-table-column :label="`${searchForm.year}年`" sortable prop="year" align="center">
				<template slot-scope="scope">
					{{ scope.row.year }}
				</template>
			</el-table-column>
		</el-table>
		<DrawerCon
			:visible.sync="drawer"
			:listNameArr="['wagesConfigIds', 'companyConfigIds']"
			@updateList="_updateList"
			:mixAble="1"
			:annualBonusNew.sync="searchForm.annualBonus"
		></DrawerCon>
	</div>
</template>
<script>
import DrawerCon from '../components/drawerCon';
import { costIntegration } from '@/api/overview';
export default {
	components: { DrawerCon },
	data() {
		return {
			loading: false,
			drawer: false,
			searchForm: {
				year: '',
				lastYear: '',
				theYearBeforeLast: '',
				annualBonus: true,
				wagesConfigIds: [],
				companyConfigIds: []
			},
			list: []
		};
	},
	methods: {
		_getList() {
			if (this.searchForm.wagesConfigIds.length > 0 && this.searchForm.companyConfigIds.length > 0) {
				this.loading = true;
				costIntegration(this.searchForm).then((res) => {
					// res.forEach(item => {
					//     item.year = Number(item.year);
					//     item.lastYear = Number(item.lastYear);
					//     item.theYearBeforeLast = Number(item.theYearBeforeLast);
					// })
					this.list = res;
					this.loading = false;
				});
			}
		},
		_export() {
			const outStr = encodeURIComponent(JSON.stringify(this.searchForm));

			const url = `/v1/overview/manpower/cost/integration/export?params=${outStr}`;

			window.open(document.location.origin + url);
		},
		_updateList(obj) {
			this.searchForm[obj.listName] = obj.listDetail;
		}
	},
	mounted() {
		const nowyear = new Date().getFullYear();

		this.searchForm.theYearBeforeLast = String(nowyear - 2);
		this.searchForm.lastYear = String(nowyear - 1);
		this.searchForm.year = String(nowyear);
	},
	watch: {
		'searchForm.year': {
			handler(newVal) {
				this.searchForm.theYearBeforeLast = String(newVal - 2);
				this.searchForm.lastYear = String(newVal - 1);
				this._getList();
			}
		},
		'searchForm.annualBonus': {
			deep: true,
			handler() {
				this._getList();
			}
		},
		'searchForm.wagesConfigIds': {
			deep: true,
			handler() {
				this._getList();
			}
		},
		'searchForm.companyConfigIds': {
			deep: true,
			handler() {
				this._getList();
			}
		}
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
</style>
